/* 
 * CSS Workaround to modify title text in FirebaseAuthUI widget
 */

.firebaseui-title {
    visibility: hidden;
    height: 0%;
}

.firebaseui-title:before {
    content: "Enter your email and you will be prompted to enter your password or create one";
    visibility: visible;
}

/* 
 *
 */
